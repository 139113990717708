import { defineStore } from 'pinia';
import type { Modals } from '~/interfaces/dto/general/modals';
import type { ISitemapRemap } from '~/interfaces/dto/sitemap/response';
import type {
  position,
  severity,
} from '~/interfaces/dto/general/toast-messages';

interface ISiteNotice {
  heading: string;
  message: string;
  severity: severity;
  enabled: boolean;
  timeout: number;
  position?: position;
}

type Nav = 'top' | 'left' | 'footer';

interface ISiteStore {
  region: string;
  regionCode: string;
  environmentBasedRegionCode: string;
  environment: 'development' | 'staging' | 'uat' | 'production' | string;
  settings: any; // Replace 'any' with the actual type of 'settings'
  redirects: { fromLink: string; toLink: string }[];
  redirectsHydrated: boolean;
  sitemapHost: string;
  cmsHost: string;
  sitemapMap: Map<string, Partial<ISitemapRemap>>;
  config: any; // Replace 'any' with the actual type for your config
  loading: boolean;
  fullscreenLoading: boolean;
  displayFilters: boolean;
  displayMarketingBanner: boolean;
  displayNotifications: boolean;
  displaySearch: boolean;
  displayLiveChat: boolean;
  siteNotice: ISiteNotice;
  unreadNotificationsCount: number;
  modalActive: boolean;
  hamburgerMenuActive: boolean;
  currentModal: Modals | undefined | string | null;
  featureFlags: Map<string, boolean>;
  logo: string;
}

export const useSiteStore = defineStore('site', {
  state: (): ISiteStore => {
    return {
      region: 'ZA',
      regionCode: 'JZA',
      environmentBasedRegionCode: 'JZA',
      environment: 'production',
      settings: {},
      redirects: [],
      redirectsHydrated: false,
      sitemapHost: 'www.jackpotcity.co.za',
      cmsHost: 'cms1.jpc.africa',
      sitemapMap: new Map(),
      config: null,
      loading: false,
      fullscreenLoading: false,
      displayFilters: false,
      displayMarketingBanner: true,
      displayNotifications: false,
      displaySearch: false,
      displayLiveChat: false,
      siteNotice: {
        heading: '',
        message: '',
        severity: 'danger',
        enabled: false,
        timeout: 3000,
      },
      unreadNotificationsCount: 0,
      modalActive: false,
      hamburgerMenuActive: false,
      currentModal: null,
      featureFlags: new Map(),
      logo: 'roa',
    };
  },
  actions: {
    toggleFeature({ feature }) {
      this[feature] = !this[feature];
      if (feature === 'displayNotifications') {
        localStorage.setItem('notificationsPanel', 'isOpen');
      }
    },
    explicitToggleFeature({
      feature,
      toggle,
    }: {
      feature: string;
      toggle: boolean;
    }) {
      this[feature] = toggle;
    },
    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },
    toggleFullscreenLoading() {
      this.fullscreenLoading = !this.fullscreenLoading;
    },
    setSiteMap(sitemap: ISitemapRemap[]) {
      this.sitemapMap.clear();
      sitemap?.forEach((sm) => this.sitemapMap.set(sm.Path, sm));
    },
    setSitemapHost(host: string) {
      this.sitemapHost = host;
    },
    setCmsHost(host: string) {
      this.cmsHost = host;
    },
    setLogo() {
      const url = useRequestURL();
      const hostName: any = url.hostname;
      const hostCheck = hostName.includes('casino');
      hostCheck ? (this.logo = 'roa') : (this.logo = 'za');
    },
    setRedirects(redirects: { fromLink: string; toLink: string }[]) {
      this.redirects = redirects;
      this.redirectsHydrated = true;
    },
    setConfig(config) {
      this.config = config;
      const featureFlags = config?.featureFlags;
      this.setLogo();
      if (!!featureFlags && featureFlags?.length > 0) {
        featureFlags.forEach((ff) => {
          const features = ff.features || ff.Features;
          const ffKey = ff.key || ff.Key;
          //Get FeatureFlag
          features.forEach((featureFlag) => {
            const featureKey = featureFlag.key;
            const enabled = featureFlag.enabled;
            //add entry into lookup table for each feature flag
            const key = `${ffKey}.${featureKey}`
              .toLowerCase()
              .trim()
              .replace('\t', '');
            this.setFeatureFlag(key, enabled);
          });
        });
      }
    },
    setEnvironment(
      environment: 'development' | 'staging' | 'uat' | 'production' | string
    ) {
      this.environment = environment;
    },
    setRegion(region: string) {
      const config = useRuntimeConfig();
      const { environment } = config.public;

      this.environment = environment;

      const r = region.toUpperCase();

      this.region = r;
      this.regionCode = `J${r}`;
      switch (environment) {
        case 'development':
          this.environmentBasedRegionCode = `J${r}DEV`;
          break;
        case 'uat':
          this.environmentBasedRegionCode = `J${r}UAT`;
          break;
        default:
          this.environmentBasedRegionCode = `J${r}`;
      }
    },
    toggleSiteNotice({ heading, message, severity, enabled, timeout }) {
      this.siteNotice = {
        ...this.siteNotice,
        heading,
        message,
        severity,
        enabled,
        timeout,
      };
    },
    setUnreadNotificationsCount(element: number) {
      this.unreadNotificationsCount = element;
    },
    activateModal(activeModal: Modals | string) {
      this.currentModal = activeModal;
      this.modalActive = true;
    },
    toggleHamburgerMenu() {
      this.hamburgerMenuActive = !this.hamburgerMenuActive;
    },
    setHamburgerMenu(active: boolean) {
      this.hamburgerMenuActive = active;
    },
    deactivateModal() {
      this.currentModal = null;
      this.modalActive = false;
    },
    setFeatureFlag(key: string, val: boolean) {
      this.featureFlags.set(key, val);
    },
  },
  getters: {
    featureDisplay(state) {
      return ({ feature }) => state[feature];
    },
    isLoading(state) {
      return state.loading;
    },
    isFullscreenLoading(state) {
      return state.fullscreenLoading;
    },
    getNav(state) {
      const sitemap = [...state.sitemapMap.values()];
      return ({ nav }: { nav: Nav }) =>
        sitemap?.filter((item, index) => {
          return item?.Navigation[nav];
        });
    },
    getRedirects(state) {
      return state.redirects;
    },
    getRedirectsHydrated(state) {
      return state.redirectsHydrated;
    },
    getSitemapMap(state) {
      return state.sitemapMap;
    },
    getSitemapHost(state) {
      return state.sitemapHost;
    },
    getCmsHost(state) {
      return state.cmsHost;
    },
    getConfig(state) {
      return state.config;
    },
    getCountry(state) {
      const countries = state.config?.countries || state.config?.Countries;

      return countries?.find(
        ({ countryIsoTwo }) =>
          countryIsoTwo === state.region || {
            countryDialingCode: '+27',
            countryIsoThree: 'ZAF',
            countryIsoTwo: 'ZA',
            countryName: 'South Africa',
          }
      );
    },
    getDialingCode() {
      return this.getCountry?.countryDialingCode;
    },
    getCurrencyCode(state) {
      return (
        (!!state.config?.currencies
          ? state.config?.currencies[0]?.currencyCode
          : state.config?.Currencies[0]?.CurrencyCode) || 'ZAR'
      );
    },
    getRegion(state) {
      return state.region;
    },
    getRegionCode(state) {
      return state.regionCode;
    },
    getEnvironment(state) {
      return state.environment;
    },
    getEnvironmentRegionCode(state) {
      return state.environmentBasedRegionCode;
    },
    getCurrentVertical(state) {
      const nav = this.getNav({ nav: 'top' });
      return (route) => nav.find((c) => c.Path === route.path) || nav[0];
    },
    getSiteNotice(state) {
      return state.siteNotice;
    },
    getUnreadNotificationsCount(state) {
      return state.unreadNotificationsCount;
    },
    getModalActive(state) {
      return state.modalActive;
    },
    getHamburgerMenuActive(state) {
      return state.hamburgerMenuActive;
    },
    getCurrenModal(state) {
      return state.currentModal;
    },
    getFeatureFlags(state) {
      return state.featureFlags;
    },
    getLogo(state) {
      return state.logo;
    },
  },
});
