import { useAuthStore } from '~/stores/auth.store';
import { BaseService } from '~/services/base.service';
import type { FetchContext } from 'ofetch';
import type { ILaunchV3Response } from '~/interfaces/dto/games/response';

export const useLaunchService = () => {
  const authStore = useAuthStore();

  const launchGame = async ({ request }) => {
    const appConfig = useRuntimeConfig();
    const base = new BaseService(appConfig.public.base);
    const requestUrl: string = base.requestUrl({
      path: 'api/v3/Gaming/launch',
    });

    return await $fetch<ILaunchV3Response>(requestUrl, {
      method: 'post',
      body: request,
      query: { environment: appConfig.public.environment || 'development' },
      headers: {
        Authorization: `Bearer ${authStore.access_token}`,
        'X-Environment': appConfig.public.environment || 'development',
      },
      onResponse(context: FetchContext & { response }): Promise<void> | void {
        if (!!context.response._data?.clientScriptData) {
          const csd = JSON.parse(context.response._data?.clientScriptData);
          context.response._data = {
            ...context.response._data,
            clientScriptData: JSON.stringify(csd || {}),
          };
        }
      },
    });
  };
  const launchFreeGame = async ({ request }) => {
    const appConfig = useRuntimeConfig();
    const base = new BaseService(appConfig.public.base);
    const requestUrl: string = base.requestUrl({
      path: 'api/v3/Gaming/launch/demo',
    });
    const { setCache, getCache } = await useCache(
      `demoplay-launch:${+request?.gameId}`
    );

    if (!!getCache) return getCache;

    return await $fetch<ILaunchV3Response>(requestUrl, {
      method: 'post',
      body: request,
      query: { environment: appConfig.public.environment || 'development' },
      headers: {
        'X-Environment': appConfig.public.environment || 'development',
      },
      async onResponse(context: FetchContext & { response }): Promise<void> {
        if (!!context.response._data?.clientScriptData) {
          const csd = JSON.parse(context.response._data?.clientScriptData);
          context.response._data = {
            ...context.response._data,
            clientScriptData: JSON.stringify(csd || {}),
          };
        }
        await setCache(context.response._data);
      },
    });
  };
  return { launchGame, launchFreeGame };
};
