import { useStorageService } from './services/useStorageService';
export default async function useCache<T>(key: string) {
  const { $config } = useNuxtApp();
  const { setIndexDb, getIndexDb } = useStorageService();

  key = [$config.public.environment.toLowerCase(), key].join('-');

  const getCache = async (): Promise<T> => {
    const csrCache = await getIndexDb({
      key,
    });
    if (!!csrCache) console.log(`Returning CSR cache ${key}`);
    return !!csrCache ? csrCache : null;
  };
  const setCache = async (data: T) => {
    if (!import.meta.server) console.log(`Setting CSR cache ${key}`);
    await setIndexDb({
      key,
      data,
      expiry: $config.public.dataCacheTime,
    });
  };
  return { getCache: await getCache(), setCache };
}
