import type {
  ISitemapNuxtRouteParams,
  ISitemapNuxtSeoParams,
  ISitemapRemap,
  Sitemap,
} from '~/interfaces/dto/sitemap/response';

export const remapSitemap = (sitemap: Sitemap[]): Partial<ISitemapRemap>[] => {
  return sitemap?.map(
    ({
      P: Path,
      C: Caption,
      N: Navigation,
      PN: PageName,
      A: Alias,
      DMC: DocumentMenuClass,
      PG: PageGuid,
      TN: IsTopNav,
      SM: IsSiteMap,
      SN: IsSideNav,
      FN: IsFooterNav,
      PD: DocumentPageDescription,
      PKW: DocumentPageKeyWords,
      MID: MenuItemDescription,
      MC: DocumentMenuCaption,
      PT: DocumentPageTitle,
      CH: Children,
      M: Mapping,
      T: Type,
      RE: RedirectUrl,
      PC: PageCategories,
      MA: MobileApp,
    }) => {
      const nuxtRouteParams: ISitemapNuxtRouteParams = {
        name: '',
        params: {},
        path:
          Path.toLowerCase() === '/aviator'
            ? '/home/featured/aviator'
            : Path.toLowerCase(),
      };
      const nuxtSeoParams: Partial<ISitemapNuxtSeoParams> = {
        title: DocumentPageTitle || `${PageName} - Jackpot city`,
        description: DocumentPageDescription || 'Jackpot city',
        ogTitle: DocumentPageTitle || `${PageName} - Jackpot city`,
        twitterTitle: DocumentPageTitle || `${PageName} - Jackpot city`,
        twitterDescription: DocumentPageDescription || 'Jackpot city',
        ogDescription: DocumentPageDescription || 'Jackpot city',
        ogImage: {
          url: 'https://media.jpc.africa/medialibraries/Jackpotcity.co.za/content-pages/placeholders/JPC_GameTilePlaceHolder_500x500.jpg',
          width: 500,
          height: 500,
        },
        twitterImage: {
          url: 'https://media.jpc.africa/medialibraries/Jackpotcity.co.za/content-pages/placeholders/JPC_GameTilePlaceHolder_500x500.jpg',
          width: 500,
          height: 500,
        },
        // ogUrl: getAbsoluteUrl(Path),
        twitterCard: 'summary',
        keywords: DocumentPageKeyWords,
      };
      return {
        nuxtRouteParams,
        nuxtSeoParams,
        Path:
          Path.toLowerCase() === '/aviator'
            ? '/home/featured/aviator'
            : Path.toLowerCase(),
        Navigation,
        PageName,
        Alias,
        DocumentPageDescription,
        DocumentPageKeyWords,
        DocumentPageTitle,
        Mapping,
        MobileApp,
        Children,
      };
    }
  );
};
