import type { NuxtApp } from '#app';
import { BaseService } from '~/services/base.service';
import { useLocaleStore } from '~/stores/locale.store';
import { useSiteStore } from '~/stores/site.store';

export const useLocaleService = (nuxt?: NuxtApp) => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.config);
  const locale = useLocaleStore(nuxt?.$pinia);
  const siteStore = useSiteStore(nuxt?.$pinia);
  const fetchLocale = async (localeCode: string) => {
    if (!localeCode) {
      try {
        const cultures = !siteStore.getConfig?.cultures?.length
          ? siteStore.getConfig?.Cultures
          : siteStore.getConfig?.cultures;

        localeCode = cultures[0]['cultureCode'] || cultures[0]['CultureCode'];
      } catch (e) {
        localeCode = 'en-US';
      }
    }
    const requestUrl: string = base.requestUrl({
      path: `cron/locales/jackpotcity/${localeCode}`,
      stitchProtocol: Boolean(
        import.meta.server && appConfig.public.enableHttpsBypass
      ),
    });
    const { setCache, getCache } = await useCache<Record<string, string>>(
      `locale-${localeCode}`
    );
    if (!!getCache) {
      locale.setLocale(getCache);
      return getCache;
    }

    return await $fetch<Record<string, string>>(requestUrl, {
      keepalive: true,
      onResponse({ response }) {
        locale.setLocaleCode(localeCode);
        locale.setLocale(response?._data);
        setCache(response?._data);
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
    });
  };
  return { fetchLocale };
};
