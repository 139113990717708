import { useConfigService } from '~/composables/services/useConfigService';
import { useStorageService } from '~/composables/services/useStorageService';
import { useSitemapService } from '~/composables/services/useSitemapService';
import { useAuthService } from '~/composables/services/useAuthService';
import { useLocaleService } from '~/composables/services/useLocaleService';
import { useWalletService } from '~/composables/services/useWalletService';
import { useGameService } from '~/composables/services/useGameService';
import { useComplianceService } from '~/composables/services/useComplianceService';
import { useContentService } from '~/composables/services/useContentService';
import { useToastService } from '~/composables/services/useToastService';
import { useLaunchService } from '~/composables/services/useLaunchService';
import { useRegistrationService } from '~/composables/services/useRegistrationService';
import { usePromotionService } from '~/composables/services/usePromotionService';
import { useMarketingService } from '~/composables/services/useMarketingService';
import type { NuxtApp } from '#app';

export const useServices = (nuxtApp?: NuxtApp) => {
  return {
    $configService: useConfigService(),
    $storageService: useStorageService(),
    $sitemapService: useSitemapService(),
    $authService: useAuthService(),
    $localeService: useLocaleService(nuxtApp),
    $walletService: useWalletService(),
    $gameService: useGameService(),
    $complianceService: useComplianceService(),
    $contentService: useContentService(),
    $toastService: useToastService(),
    $launchService: useLaunchService(),
    $registrationService: useRegistrationService(),
    $promotionService: usePromotionService(),
    $marketingService: useMarketingService(),
  };
};
