import type { IConfigResponse } from '~/interfaces/dto/config/response';

export const configMapper = (
  config: IConfigResponse
): Partial<IConfigResponse> => {
  const clone = { ...config };
  delete clone.verticals;
  delete clone.Verticals;
  delete clone.casinoVerticals;
  delete clone.CasinoVerticals;
  delete clone.devConfig;
  delete clone.DevConfig;
  delete clone.properties;
  delete clone.Properties;
  delete clone.applications;
  delete clone.Applications;
  delete clone.channels;
  delete clone.Channels;
  return clone;
};
