import type { IMarketingSessionAddRequest } from '~/interfaces/dto/marketing/request';
import { useStorageService } from '~/composables/services/useStorageService';
import { useMarketingService } from '~/composables/services/useMarketingService';
import { useSiteStore } from '~/stores/site.store';

export const useAddMarketingSession = async () => {
  const site = useSiteStore();
  const route = useRoute();
  const { query } = route;

  const $storageService = useStorageService();
  const $marketingService = useMarketingService();

  const { deviceInfo, IpAddress } = $storageService.getUniversalStorage({
    key: 'device-info',
  });

  if (!sessionStorage.getItem('MkSession')) {
    const regionId: number = 13;
    const platformIds = {
      mobile: 1,
      tablet: 1,
      desktop: 2,
      ios: 3,
      android: 4,
      huawei: 5,
    };

    const appType =
      String(query?.appType) ||
      (deviceInfo.IsMobile || deviceInfo.IsTablet ? 'mobile' : 'desktop');

    const sessionData: IMarketingSessionAddRequest = {
      platformID: platformIds[appType.toLowerCase()],
      regionID: regionId,
      vendorAccountID: $storageService.getLocalStorage({
        key: 'auth:userId',
      }),
      dataFree: false,
      bannerTag: $storageService.getCookie({ key: 'BTAGCOOKIE' }),
      queryParamString: $storageService.getCookie({
        key: 'OriginalQueryString',
      }),
      ipAddress: IpAddress,
      countryCode: site.getRegionCode,
      userAgent: navigator.userAgent,
      sessionTrackingToken: '',
      sessionMetaData: [
        {
          cookieName: 'REFERRERBTAGCOOKIE',
          cookieValue: $storageService.getCookie({
            key: 'REFERRERBTAGCOOKIE',
          }),
          cookieDomain: '',
        },
        {
          cookieName: 'MT',
          cookieValue: $storageService.getCookie({ key: 'MT' }),
          cookieDomain: '',
        },
        {
          cookieName: 'ST',
          cookieValue: $storageService.getCookie({ key: 'ST' }),
          cookieDomain: '',
        },
      ],
      ...deviceInfo,
    };

    $marketingService.setMarketingSession(sessionData);
  }
};
