import { BaseService } from '~/services/base.service';
import { useStorageService } from '~/composables/services/useStorageService';
import type {
  IBannerTagResponse,
  IInfoResponse,
  IMarketingOptInResponse,
  IMarketingSessionAddResponse,
  ITokensResponse,
} from '~/interfaces/dto/marketing/response';
import type {
  IMarketingOptInRequest,
  IMarketingSessionAddRequest,
} from '~/interfaces/dto/marketing/request';

export const useMarketingService = () => {
  const appConfig = useRuntimeConfig();

  const $storageService = useStorageService();

  const base = new BaseService(appConfig.public.base);
  const info = new BaseService(appConfig.public.info);

  const route = useRoute();
  const fetchBannerTags = async ({
    referrer,
  }: {
    referrer: string;
  }): Promise<IBannerTagResponse> => {
    const requestUrl: string = info.requestUrl({
      path: `api/v1/origin/bannertag`,
      queryParams: {
        'request.referringUrl': referrer,
      },
    });
    const currentBtag =
      $storageService.getCookie({ key: 'BTAGCOOKIE' }) || route.query?.btag;
    const currentReferrerBtag = $storageService.getCookie({
      key: 'REFERRERBTAGCOOKIE',
    });

    return await $fetch<IBannerTagResponse>(requestUrl, {
      headers: {
        'X-OT-Origin': window.location.origin,
      },
      onResponse({ response }) {
        if (!currentBtag)
          $storageService.setCookie({
            key: 'BTAGCOOKIE',
            data: response._data?.BannerTagString,
            expiry: 7,
          });
        if (!currentReferrerBtag)
          $storageService.setCookie({
            key: 'REFERRERBTAGCOOKIE',
            data: response._data?.BannerTagString,
            expiry: 0,
          });
      },
      onResponseError({ response }) {
        console.error(response);
      },
      onRequestError({ response }) {
        console.error(response);
      },
    });
  };
  const fetchOriginTokens = async (): Promise<ITokensResponse> => {
    const requestUrl: string = info.requestUrl({
      path: `api/v1/origin/tokens`,
    });
    const masterToken = $storageService.getCookie({ key: 'MT' });
    const sessionToken = $storageService.getCookie({ key: 'ST' });
    return await $fetch<ITokensResponse>(requestUrl, {
      onResponse({ response }) {
        if (!masterToken)
          $storageService.setCookie({
            key: 'MT',
            data: response._data?.MasterToken,
            expiry: 1825,
          });
        if (!sessionToken)
          $storageService.setCookie({
            key: 'ST',
            data: response._data?.MasterToken,
            expiry: 0,
          });
      },
      onResponseError({ response }) {
        console.error(response);
      },
      onRequestError({ response }) {
        console.error(response);
      },
    });
  };
  const fetchDeviceInfo = async (): Promise<IInfoResponse> => {
    const requestUrl: string = info.requestUrl({
      path: `api/v1/requestInfo`,
    });
    return $fetch(requestUrl, {
      onResponseError({ response }) {
        console.error(response);
      },
      onRequestError({ response }) {
        console.error(response);
      },
    });
  };

  const setMarketingSession = async (
    session: IMarketingSessionAddRequest
  ): Promise<IMarketingSessionAddResponse> => {
    const requestUrl: string = base.requestUrl({
      path: `api/v1/marketing/addmarketingsession`,
    });
    return await $fetch<IMarketingSessionAddResponse>(requestUrl, {
      method: 'post',
      body: session,
      onResponse() {
        $storageService.setSessionStorage({
          key: 'MkSession',
          data: String(true),
        });
      },
      onResponseError({ response }) {
        console.error(response);
      },
      onRequestError({ response }) {
        console.error(response);
      },
    });
  };

  const setMarketingOptIn = async (
    session: IMarketingOptInRequest
  ): Promise<IMarketingOptInResponse> => {
    const requestUrl: string = base.requestUrl({
      path: `api/v1/marketing/insertmarketingoptin`,
    });
    return await $fetch<IMarketingOptInResponse>(requestUrl, {
      method: 'post',
      body: session,
      onResponseError({ response }) {
        console.error(response);
      },
      onRequestError({ response }) {
        console.error(response);
      },
    });
  };
  return {
    fetchBannerTags,
    fetchOriginTokens,
    fetchDeviceInfo,
    setMarketingSession,
    setMarketingOptIn,
  };
};
