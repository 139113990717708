import { BaseService } from '~/services/base.service';
import { useSiteStore } from '~/stores/site.store';
import type { IConfigResponse } from '~/interfaces/dto/config/response';
import { configMapper } from '~/utilities/config-mapper';
import { remapSitemap } from '~/utilities/sitemap-mapper';

export const useConfigService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.config);
  const siteStore = useSiteStore();
  const localeStore = useLocaleStore();
  const fetchConfig = async (countryCode: string) => {
    const requestUrl: string = base.requestUrl({
      path: `cron/appsettings/jackpotcity/${countryCode}`,
      stitchProtocol: Boolean(
        import.meta.server && appConfig.public.enableHttpsBypass
      ),
    });
    const { setCache, getCache } = await useCache(`config-${countryCode}`);
    if (!!getCache) {
      siteStore.setConfig(getCache);
      return getCache;
    }

    return await $fetch<IConfigResponse>(requestUrl, {
      keepalive: true,
      onResponse({ response }) {
        const remappedConfig = configMapper(response._data);
        siteStore.setConfig(remappedConfig);
        setCache(remappedConfig);
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
      onResponseError(context) {
        console.error('[RESPONSE ERROR]:', context);
      },
    });
  };
  const fetchRedirects = async (countryCode: string) => {
    const requestUrl: string = base.requestUrl({
      path: `cron/redirects/jackpotcity/${countryCode}`,
      stitchProtocol: Boolean(
        import.meta.server && appConfig.public.enableHttpsBypass
      ),
    });
    const { setCache, getCache } = await useCache(`redirects-${countryCode}`);

    if (!!getCache) {
      siteStore.setRedirects(getCache);
      return getCache;
    }

    return await $fetch<{ fromLink: string; toLink: string }[]>(requestUrl, {
      keepalive: true,
      onResponse({ response }) {
        siteStore.setRedirects(response._data);
        setCache(response._data);
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
      onResponseError(context) {
        console.error('[RESPONSE ERROR]:', context);
      },
    });
  };
  const fetchFullConfig = async (countryCode: string, localeCode = 'en-US') => {
    const transformAndSetState = (data) => {
      const remappedConfig = configMapper(data?.appsettings);
      siteStore.setConfig(remappedConfig);

      //sitemap
      const remappedSitemap = remapSitemap(data?.sitemaps?.sitemap);
      siteStore.setSitemapHost(data?.sitemaps?.request?.host);
      siteStore.setSiteMap(remappedSitemap);
      //redirects
      siteStore.setRedirects(data?.redirects);
      localeStore.setLocale(data?.locales);
    };

    const requestUrl: string = base.requestUrl({
      path: `cron/app-data/${countryCode}/webdesktop/jackpotcity/${localeCode}`,
      stitchProtocol: Boolean(
        import.meta.server && appConfig.public.enableHttpsBypass
      ),
      lowercaseTransform: false,
    });
    const { setCache, getCache } = await useCache(
      `full-config-${countryCode}-${localeCode}`
    );
    if (!!getCache) {
      transformAndSetState(getCache);
      return getCache;
    }

    return await $fetch<IConfigResponse>(requestUrl, {
      keepalive: true,
      async onResponse({ response }) {
        const controlValidation = [
          response._data?.appsettings,
          response._data?.sitemaps,
          response._data?.locales,
        ];
        const failedValidations = controlValidation.some((cv) => {
          return !!cv ? false : true;
        });

        transformAndSetState(response._data);
        if (!failedValidations) {
          await setCache(response._data);
        }
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
      onResponseError(context) {
        console.error('[RESPONSE ERROR]:', context);
      },
    });
  };

  return { fetchConfig, fetchRedirects, fetchFullConfig };
};
