import { defineStore } from 'pinia';
export const useGameStore = defineStore('games', {
  state: () => {
    return {
      categoriesAndGamesMap: new Map(),
      categoriesAndGames: [],
      categories: [],
      games: [],
      gamesMap: new Map(),
      themes: [],
      providers: [],
      buildVersion: '0',
      disabledProviders: [],
      hiddenProviders: [],
      favorites: [],
      favoriteIds: [],
      currentLaunchUrl: '',
      currentGame: null,
      currentCategory: null,
      recentlyPlayed: [],
      currentGameRating: null,
      limiter: 25,
    };
  },
  actions: {
    setGameStore(data) {
      const categories = data?.categories;
      categories?.forEach((cat) =>
        this.categoriesAndGamesMap.set(cat.name, cat)
      );
      if (typeof categories === 'undefined') return;
      this.categoriesAndGames = [...categories];
      this.categories = categories.map((category) => category.name);

      this.setCategories();
      this.providers = [...data.providers];
      this.themes = [...data.themes];
    },
    addGames(data) {
      if (!!data) this.games = [...this.games, ...data];
    },
    setCurrentLaunchUrl(url: string) {
      this.currentLaunchUrl = url;
    },
    setCurrentGame(game: any) {
      this.currentGame = game;
    },
    setCurrentCategory(category: string) {
      this.currentCategory = category;
    },
    setFavorites(data: Array<any>) {
      if (!data) return;
      this.favorites = data;
      this.favoriteIds = data.map((val) => val.id);
      this.setCategories();
    },
    setFavorite(game: any, add: boolean) {
      //Updating favorites on game store
      if (!this.favoriteIds.includes(game.id)) {
        if (add) {
          this.favorites.push(game);
          this.favoriteIds.push(game.id);
        }
      } else {
        this.favorites = this.favorites.filter((el) => el.id !== game.id);
        this.favoriteIds = this.favoriteIds.filter((el) => el !== game.id);
      }

      this.setCategories();
    },
    clearGameState() {
      this.currentGame = {};
      this.currentLaunchUrl = '';
      this.currentGameRating = null;
    },
    clearCompleteState() {
      this.categoriesAndGames = [];
      this.currentCategory = null;
      this.categories = [];
      this.games = [];
      this.themes = [];
      this.providers = [];
      this.categoriesAndGamesMap = new Map();
    },
    setRecentlyPlayed(game, userId) {
      const gameInState = this.recentlyPlayed.find((g) => g?.id === game.id);
      if (!!gameInState) {
        const excludeGameInState = this.recentlyPlayed.filter(
          (g) => g.id !== gameInState.id
        );
        this.recentlyPlayed = [gameInState, ...excludeGameInState];
      } else {
        this.recentlyPlayed = [game, ...this.recentlyPlayed];
      }
      const { $storageService } = useServices();

      $storageService.setLocalStorage({
        key: `recently-played:${userId}`,
        data: this.recentlyPlayed,
      });
    },
    hydrateRecentlyPlayed(userId) {
      const { $storageService } = useServices();

      this.recentlyPlayed =
        $storageService.getLocalStorage({
          key: `recently-played:${userId}`,
        }) || [];
    },
    setGameRating(rating: number) {
      this.currentGameRating = rating;
    },
    setCategories() {
      if (!this.categories.length) return;
      if (!this.categories.includes('all')) {
        this.categories.unshift('all');
      }

      if (
        this.returnFavorites.length > 0 &&
        !this.categories.includes('favorites') &&
        !this.categories.includes('all')
      ) {
        const index = 0;
        const value = 'favorites';

        this.categories.splice(index, 0, value);
      } else if (
        this.returnFavorites.length > 0 &&
        !this.categories.includes('favorites') &&
        this.categories.includes('all')
      ) {
        const index = 1;
        const value = 'favorites';

        this.categories.splice(index, 0, value);
      } else if (
        this.returnFavorites.length <= 0 &&
        this.categories.includes('favorites')
      ) {
        this.categories.splice(1, 1);
      }
      if (!this.categories.includes('providers')) {
        this.categories.push('providers');
      }
    },
    setCurrentGameLikes(likes: number) {
      if (!(likes < 0)) {
        this.currentGame.likesCount = likes;
      }
    },
    clearRecentlyPlayed() {
      this.recentlyPlayed = [];
    },
    clearFavourites() {
      this.favorites = [];
      this.favoriteIds = [];
    },
    setBuildInformation({
      buildVersion,
      hiddenProviders,
      disabledProviders,
    }: {
      buildVersion: string;
      hiddenProviders: string[];
      disabledProviders: string[];
    }) {
      this.buildVersion = buildVersion;
      this.hiddenProviders = [...hiddenProviders];
      this.disabledProviders = [...disabledProviders];
    },
  },
  getters: {
    categoriesAvailable(state) {
      return state.categories.length > 0;
    },
    returnCategoriesAndGames(state) {
      return state.categoriesAndGames;
    },
    returnCategoriesAndGamesMap(state) {
      return state.categoriesAndGamesMap;
    },
    returnCategories(state) {
      return state.categories;
    },
    returnThemes(state) {
      return state.themes;
    },
    returnProviders(state) {
      return state.providers;
    },
    returnFullCategory(state) {
      return (category: string) => {
        return state.categoriesAndGamesMap.get(category);
      };
    },
    returnCurrentCategory(state) {
      return state.currentCategory;
    },
    returnCategory(state) {
      return ({ category }) => {
        if (category === 'favorites') return state.favorites;
        if (category === 'recentlyPlayed') return state.recentlyPlayed;

        return state.categoriesAndGamesMap.get(category).games;
      };
    },
    isFavorite(state) {
      return (id) => {
        if (!state.favoriteIds.length) return false;
        const favorite = state.favoriteIds.some((fav) => fav === id);
        return favorite;
      };
    },
    returnFavorites(state) {
      return state.favorites;
    },
    returnRecentlyPlayed(state) {
      return state.recentlyPlayed;
    },
    returnFavoriteIds(state) {
      return state.favoriteIds;
    },
    returnCurrentGameUrl(state) {
      return state.currentLaunchUrl;
    },
    returnCurrentGame(state) {
      return state.currentGame;
    },
    returnCurrentGameLikes(state) {
      return state.currentGame?.likesCount;
    },
    returnGameRating(state) {
      return state.currentGameRating;
    },
    returnLimiter(state) {
      return state.limiter;
    },
    returnHiddenProviders(state) {
      return state.hiddenProviders;
    },
    returnDisabledProviders(state) {
      return state.disabledProviders;
    },
  },
});
