import { BaseService } from '~/services/base.service';
import type { ISitemapResponse } from '~/interfaces/dto/sitemap/response';
import { useSiteStore } from '~/stores/site.store';
import { remapSitemap } from '~/utilities/sitemap-mapper';

export const useSitemapService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.config);
  const settings = useSiteStore();

  const fetchSitemap = async (countryCode: string) => {
    const requestUrl: string = base.requestUrl({
      path: `cron/sitemaps/jackpotcity/${countryCode}`,
      stitchProtocol: Boolean(
        import.meta.server && appConfig.public.enableHttpsBypass
      ),
    });

    const { setCache, getCache } = await useCache(`sitemap-${countryCode}`);

    const modifyHost = (host: string) => {
      const regex = /^https?:\/\/cms1(.)([a-zA-Z])\w+(.com)/g;
      const moddedHost = host?.replace(regex, 'cms1.jpc.africa');
      settings.setCmsHost(moddedHost);
    };

    if (!!getCache) {
      const sitemap = remapSitemap(getCache.siteMaps[0]?.sitemap);
      settings.setSiteMap(sitemap);
      settings.setSitemapHost(getCache.siteMaps[0]?.request?.host);
      modifyHost(getCache.siteMaps[0]?.variables?.cms);
      return getCache;
    }

    return await $fetch<{ siteMaps: ISitemapResponse[] }>(requestUrl, {
      retry: false,
      keepalive: true,
      onResponse({ response }) {
        try {
          const sitemap = remapSitemap(response._data.siteMaps[0]?.sitemap);
          settings.setSiteMap(sitemap);
          settings.setSitemapHost(response._data.siteMaps[0]?.request?.host);
          modifyHost(response._data.siteMaps[0]?.variables?.cms);
          if (!!response._data.siteMaps) setCache(response._data);
        } catch (e) {
          console.error(e);
        }
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
        showError({
          statusCode: 500,
          statusMessage: 'Could not load site pages',
          fatal: true,
        });
      },
      onResponseError(context) {
        console.error('[RESPONSE ERROR]:', context);
      },
    });
  };

  return { fetchSitemap };
};
